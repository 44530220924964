import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const CodeCampaigns = Suspendable(
  lazy(() =>
    import('@modules/codeCampaigns/controller/index').then((module) => ({
      default: module.default,
    }))
  )
);

const CodeCampaign = Suspendable(
  lazy(() =>
    import(
      '@modules/codeCampaigns/submodules/codeCampaign/controller/CodeCampaignController'
    ).then((module) => ({
      default: module.default,
    }))
  )
);

export const CodeCampaignRoutes: RouteObject[] = [
  {
    element: <CodeCampaigns />,
    path: ROUTES.CODE_CAMPAIGNS.ROOT,
  },
  {
    path: ROUTES.CODE_CAMPAIGNS.CREATE,
    element: <CodeCampaign mode="create" />,
  },
  {
    path: ROUTES.CODE_CAMPAIGNS.EDIT,
    element: <CodeCampaign mode="edit" />,
  },
  {
    path: ROUTES.CODE_CAMPAIGNS.CLONE,
    element: <CodeCampaign mode="clone" />,
  },
];
