import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import { type Option } from '@shared/form/types';

interface IResponseToSelectOptions {
  key: 'code' | 'name' | 'title' | 'displayName';
}
export function responseToSelectOptions<T>(
  response: T,
  options?: IResponseToSelectOptions
): Option[] {
  if (isEmpty(response)) {
    return [];
  }
  if (Array.isArray(response)) {
    return response.map((item) => ({
      value: item.id ?? item,
      label: options?.key ? item[options.key] : item,
    }));
  }

  if (isObject(response)) {
    return Object.entries(response).map(([key]) => ({
      label: key,
      value: key,
    }));
  }

  return [];
}
