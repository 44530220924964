import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const AppSettingsController = Suspendable(
  lazy(() =>
    import('@modules/appSettings/controller').then((module) => ({
      default: module.AppSettingsController,
    }))
  )
);
export const AppSettings: RouteObject[] = [
  {
    path: ROUTES.APP_SETTINGS.ROOT,
    element: <AppSettingsController />,
  },
];
