import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const PlayerOffers = Suspendable(
  lazy(() =>
    import('@modules/playerOffers/controller').then((module) => ({
      default: module.PlayerOffers,
    }))
  )
);

const PlayerOffersDetails = Suspendable(
  lazy(() =>
    import(
      '@modules/playerOffers/submodules/PlayerOfferDetails/controller'
    ).then((module) => ({
      default: module.PlayerOfferDetailsController,
    }))
  )
);

export const PlayerOffersRoutes: RouteObject[] = [
  {
    element: <PlayerOffers />,
    path: ROUTES.PLAYER_OFFERS.ROOT,
  },
  {
    element: <PlayerOffersDetails />,
    path: ROUTES.PLAYER_OFFERS.DETAILS,
  },
];
