import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type IQueryPayload } from '@ui/rule-engine';

import {
  type IInitialFilter,
  type ISearchParams,
  OPERATOR_SORT,
  OPERATOR_TYPE,
} from '@features/filters/types';
import { initialFilters } from '@modules/automaticCampaigns/constants';
import { type ICampaignBonusTemplateRow } from '@modules/automaticCampaigns/types';
import { type Option } from '@shared/form/types';

interface InitialState {
  searchParams: ISearchParams;
  prevFilters: Record<string, IInitialFilter>;
  dialogState: {
    isConfirmBrandsDialogOpen: boolean;
  };
  activeAutomaticCampaign: {
    enabled: boolean;
    id: null | string;
    name: null | string;
  };
  campaignConfig: {
    // TODO: Remove after Orbit impl
    module: string;
    trigger: string;
    activeTab: number;
    actionType: string;
    queryError: boolean;
    query?: IQueryPayload;
    currentBrands: Option[];
    isGeneralErrors: boolean;
    previousBrands: Option[];
    brandChangeReason: string;
    isRelatedCampaign: boolean;
    currentQuery?: IQueryPayload;
    hasBrandsWithoutAccess: boolean;
    isClearRelatedCampaign: boolean;
    isFetchRelatedCampaign: boolean;
    bonusTemplates: ICampaignBonusTemplateRow[];
  };
}

export const initialAutomaticCampaignSearchParams = {
  page: 1,
  size: 10,
  filters: [{ value: true, field: 'enabled', type: OPERATOR_TYPE.EQUAL }],
  sorts: [
    {
      field: 'modified_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: InitialState = {
  prevFilters: initialFilters,
  searchParams: initialAutomaticCampaignSearchParams,
  dialogState: {
    isConfirmBrandsDialogOpen: false,
  },
  activeAutomaticCampaign: {
    id: null,
    name: null,
    enabled: false,
  },
  campaignConfig: {
    // TODO: Remove after Orbit impl
    module: '',
    trigger: '',
    activeTab: 0,
    actionType: '',
    query: undefined,
    queryError: false,
    currentBrands: [],
    previousBrands: [],
    bonusTemplates: [],
    brandChangeReason: '',
    isGeneralErrors: false,
    currentQuery: undefined,
    isRelatedCampaign: false,
    isFetchRelatedCampaign: false,
    isClearRelatedCampaign: false,
    hasBrandsWithoutAccess: false,
  },
};

const automaticCampaignsSlice = createSlice({
  initialState,
  name: 'automaticCampaignsSlice',
  reducers: {
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
    setPrevFilters(
      state: InitialState,
      action: PayloadAction<Record<string, IInitialFilter>>
    ) {
      state.prevFilters = action.payload;
    },
    setActiveAutomaticCampaign(
      state: InitialState,
      action: PayloadAction<InitialState['activeAutomaticCampaign']>
    ) {
      state.activeAutomaticCampaign = action.payload;
    },
    setCampaignDialogState(
      state: InitialState,
      action: PayloadAction<Partial<InitialState['dialogState']>>
    ) {
      state.dialogState = { ...state.dialogState, ...action.payload };
    },
    setQueryPayload(
      state: InitialState,
      action: PayloadAction<Partial<InitialState['campaignConfig']>>
    ) {
      state.campaignConfig = { ...state.campaignConfig, ...action.payload };
    },
    setCampaignConfig(
      state: InitialState,
      action: PayloadAction<Partial<InitialState['campaignConfig']>>
    ) {
      state.campaignConfig = { ...state.campaignConfig, ...action.payload };
    },
  },
});

export default automaticCampaignsSlice.reducer;

export const {
  setPrevFilters,
  setQueryPayload,
  setSearchParams,
  setCampaignConfig,
  setCampaignDialogState,
  setActiveAutomaticCampaign,
} = automaticCampaignsSlice.actions;
