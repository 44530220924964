export default function Tabs() {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '2rem',
          position: 'relative',
          '& .MuiButtonBase-root:first-of-type': {
            padding: '0',
            marginLeft: '0',
          },
          '& .MuiButtonBase-root': {
            padding: '0',
            minWidth: 'auto',
            minHeight: '2rem',
            marginLeft: '1rem',
          },
        },
      },
    },
  };
}
