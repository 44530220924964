import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { ErrorComponent, Suspendable } from '@components';

import { ROUTES } from '@constants';
import { ActivateCode } from '@routes/ActivateCodeRoutes';
import { AppSettings } from '@routes/AppSettingsRoutes';
import { AutomaticCampaignRoutes } from '@routes/AutomaticCampaignRoutes';
import { BonusRoutes } from '@routes/BonusRoutes';
import { CampaignActivityRoutes } from '@routes/CampaignActivityRoutes';
import { CampaignMonitorRoutes } from '@routes/CampaignMonitorReport';
import { CodeCampaignRoutes } from '@routes/CodeCampaignRoutes';
import { GiveBonusManagement } from '@routes/GiveBonusRoutes';
import { IframeMode } from '@routes/IframeModeRoutes';
import { PlayerBonusesRoutes } from '@routes/PlayerBonusesRoutes';
import { PlayerCodesRoutes } from '@routes/PlayerCodesReportRoutes';
import { PlayerOffersRoutes } from '@routes/PlayerOffersReportRoutes';
import { ScheduledCampaignRoutes } from '@routes/ScheduledCampaigns';

// Layouts
const MainLayout = Suspendable(
  lazy(() =>
    import('@layout/MainLayout').then((module) => ({
      default: module.MainLayout,
    }))
  )
);

// Modules ...

const ModuleRoutes: RouteObject = {
  path: ROUTES.ROOT,
  element: <MainLayout />,
  errorElement: <ErrorComponent />,
  children: [
    ...BonusRoutes,
    ...GiveBonusManagement,
    ...ActivateCode,
    ...AutomaticCampaignRoutes,
    ...ScheduledCampaignRoutes,
    ...PlayerBonusesRoutes,
    ...CampaignActivityRoutes,
    ...CampaignMonitorRoutes,
    ...PlayerCodesRoutes,
    ...PlayerOffersRoutes,
    ...AppSettings,
    ...IframeMode,
    ...CodeCampaignRoutes,
  ],
};

export default ModuleRoutes;
