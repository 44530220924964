import type { Theme } from '@mui/material/styles';
import merge from 'lodash/merge';

import Accordion from '@themes/overrides/Accordion';
import Autocomplete from '@themes/overrides/Autocomplete';
import Button from '@themes/overrides/Button';
import ButtonBase from '@themes/overrides/ButtonBase';
import CardContent from '@themes/overrides/CardContent';
import Checkbox from '@themes/overrides/Checkbox';
import Chip from '@themes/overrides/Chip';
import DataGrid from '@themes/overrides/DataGrid';
import DateTimePicker from '@themes/overrides/DateTimePicker';
import Dialog from '@themes/overrides/Dialog';
import IconButton from '@themes/overrides/IconButton';
import InputBase from '@themes/overrides/InputBase';
import Link from '@themes/overrides/Link';
import ListItemButton from '@themes/overrides/ListItemButton';
import ListItemIcon from '@themes/overrides/ListItemIcon';
import Pagination from '@themes/overrides/Pagination';
import PaginationItem from '@themes/overrides/PaginationItem';
import Radio from '@themes/overrides/Radio';
import Switch from '@themes/overrides/Switch';
import TableBody from '@themes/overrides/TableBody';
import TableCell from '@themes/overrides/TableCell';
import TableFooter from '@themes/overrides/TableFooter';
import TableHead from '@themes/overrides/TableHead';
import TablePagination from '@themes/overrides/TablePagination';
import TableRow from '@themes/overrides/TableRow';
import Tabs from '@themes/overrides/Tabs';
import ToggleButton from '@themes/overrides/ToggleButton';
import Tooltip from '@themes/overrides/Tooltip';
import Typography from '@themes/overrides/Typography';

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    ButtonBase(),
    CardContent(),
    Checkbox(theme),
    Chip(theme),
    IconButton(theme),
    InputBase(theme),
    DateTimePicker(),
    Link(),
    ListItemButton(theme),
    ListItemIcon(theme),
    Pagination(),
    PaginationItem(theme),
    Radio(theme),
    Switch(theme),
    TableBody(theme),
    TableCell(theme),
    TableFooter(theme),
    TableHead(theme),
    TablePagination(),
    TableRow(),
    ToggleButton(theme),
    Tooltip(theme),
    Tabs(),
    Typography(),
    Autocomplete(),
    Accordion(theme),
    DataGrid(theme),
    Dialog()
  );
}
