export default function DateTimePicker() {
  return {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '& .MuiMultiSectionDigitalClock-root::after': {
            display: 'none',
          },
        },
      },
    },
  };
}
