import {
  Card,
  CardContent,
  type CardContentProps,
  CardHeader,
  type CardHeaderProps,
  type CardProps,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { type CSSProperties, forwardRef, type ReactNode } from 'react';

const headerSX = {
  p: 1.5,
  '& .MuiCardHeader-action': {
    width: '100%',
    margin: '0px auto',
    alignSelf: 'center',
  },
};

interface IMainCard {
  modal?: boolean;
  shadow?: string;
  border?: boolean;
  content?: boolean;
  divider?: boolean;
  elevation?: number;
  boxShadow?: boolean;
  codeString?: string;
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  style?: CSSProperties;
  codeHighlight?: boolean;
  title?: string | ReactNode;
  children: string | ReactNode;
  subheader?: string | ReactNode;
  contentSX?: CardContentProps['sx'];
  cardHeaderSX?: CardHeaderProps['sx'];
  secondary?: CardHeaderProps['action'];
}

export const MainCard = forwardRef<HTMLDivElement, IMainCard>(
  (
    {
      title,
      shadow,
      sx = {},
      children,
      boxShadow,
      subheader,
      darkTitle,
      elevation,
      secondary,
      codeString,
      border = true,
      modal = false,
      content = true,
      contentSX = {},
      divider = true,
      cardHeaderSX = {},
      codeHighlight = false,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    boxShadow = theme.palette.mode === 'dark' ? (boxShadow ?? true) : boxShadow;

    return (
      <Card
        elevation={elevation ?? 0}
        ref={ref}
        {...others}
        sx={{
          borderRadius: 1,
          position: 'relative',
          border: border ? '1px solid' : 'none',
          borderColor:
            theme.palette.mode === 'dark'
              ? theme.palette.divider
              : theme.palette.grey.A800,
          ':hover': {
            boxShadow: boxShadow
              ? (shadow ?? theme.customShadows.z1)
              : 'inherit',
          },
          boxShadow:
            boxShadow && (!border || theme.palette.mode === 'dark')
              ? (shadow ?? theme.customShadows.z1)
              : 'inherit',
          ...(codeHighlight && {
            '& pre': {
              margin: 0,
              fontSize: '0.75rem',
              p: '12px !important',
              fontFamily: theme.typography.fontFamily,
            },
          }),
          ...(modal && {
            top: '50%',
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            width: { sm: 'auto', xs: `calc( 100% - 50px)` },
            '& .MuiCardContent-root': {
              overflowY: 'auto',
              minHeight: 'auto',
              maxHeight: `calc(100vh - 200px)`,
            },
          }),
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && secondary && (
          <CardHeader
            action={secondary}
            subheader={subheader}
            sx={{ ...headerSX, ...cardHeaderSX }}
            title={title}
            titleTypographyProps={{ variant: 'subtitle1' }}
          />
        )}
        {darkTitle && title && secondary && (
          <CardHeader
            action={secondary}
            sx={{ ...headerSX, ...cardHeaderSX }}
            title={<Typography variant="h4">{title}</Typography>}
          />
        )}

        {/* content & header divider */}
        {title && divider && <Divider />}

        {/* card content */}
        {content && <CardContent sx={contentSX}>{children}</CardContent>}
        {!content && children}

        {/* card footer - clipboard & highlighter  */}
        {codeHighlight && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />
            {children}
          </>
        )}
      </Card>
    );
  }
);
