import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const BonusManagement = Suspendable(
  lazy(() =>
    import('@modules/bonusManagement/controller').then((module) => ({
      default: module.BonusesController,
    }))
  )
);

const Bonus = Suspendable(
  lazy(() =>
    import(
      '@modules/bonusManagement/submodules/bonus/controller/BonusController'
    ).then((module) => ({
      default: module.default,
    }))
  )
);

export const BonusRoutes: RouteObject[] = [
  {
    element: <BonusManagement />,
    path: ROUTES.BONUS_MANAGEMENT.ROOT,
  },
  {
    element: <Bonus mode="create" />,
    path: ROUTES.BONUS_MANAGEMENT.CREATE,
  },
  {
    element: <Bonus mode="edit" />,
    path: ROUTES.BONUS_MANAGEMENT.EDIT,
  },
  {
    element: <Bonus mode="clone" />,
    path: ROUTES.BONUS_MANAGEMENT.CLONE,
  },
];
