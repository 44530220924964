import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { LuRefreshCw } from 'react-icons/lu';
import { useRouteError } from 'react-router-dom';

import * as Sentry from '@sentry/react';

export const ErrorComponent = () => {
  const theme = useTheme();
  const routeError = useRouteError();

  useEffect(() => {
    if (routeError) {
      Sentry.captureException(routeError);
    }
  }, [routeError]);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      spacing={0}
      sx={{ minHeight: '100vh' }}
    >
      <Stack alignItems="center" justifyContent="center">
        <LuRefreshCw color={theme.palette.primary.dark} size="9rem" />
        <Typography paddingTop="4rem" variant="h2">
          We have got a new version ready.
        </Typography>
        <Typography paddingTop="1rem" variant="h2">
          We will do refresh for you!
        </Typography>
      </Stack>
    </Grid>
  );
};
