import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type IFilterChip,
  type ISearchParams,
  OPERATOR_SORT,
} from '@features/filters/types';
import { type IFilterItem } from '@modules/playerBonuses/types';

interface InitialState {
  chipList: IFilterChip[];
  filterList: IFilterItem[];
  searchParams: ISearchParams;
}

export const initialPlayerBonusesSearchParams = {
  page: 1,
  size: 10,
  filters: [],
  includeExternalBonuses: true,
  sorts: [
    {
      field: 'given_at',
      order: OPERATOR_SORT.DESC,
    },
  ],
};

const initialState: InitialState = {
  chipList: [],
  filterList: [],
  searchParams: initialPlayerBonusesSearchParams,
};

const playerBonusesSlice = createSlice({
  initialState,
  name: 'playerBonusesSlice',
  reducers: {
    resetChipList(state: InitialState) {
      state.chipList = initialState.chipList;
    },
    setChipList(state: InitialState, action: PayloadAction<IFilterChip[]>) {
      state.chipList = action.payload;
    },
    setFilterList(state: InitialState, action: PayloadAction<IFilterItem[]>) {
      state.filterList = action.payload;
    },
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
    addChipItem(state: InitialState, action: PayloadAction<IFilterChip>) {
      state.chipList = [...state.chipList, action.payload];
    },
    addFilter(state: InitialState, action: PayloadAction<IFilterItem>) {
      state.filterList = [...state.filterList, action.payload];
    },
    removeChipItem(state: InitialState, action: PayloadAction<string>) {
      state.chipList = state.chipList.filter(
        (item) => item.field !== action.payload
      );
    },
    removeFilter(state: InitialState, action: PayloadAction<string>) {
      state.filterList = state.filterList.filter(
        (item) => item.value !== action.payload
      );
    },
  },
});

export default playerBonusesSlice.reducer;

export const {
  addFilter,
  addChipItem,
  setChipList,
  removeFilter,
  resetChipList,
  setFilterList,
  removeChipItem,
  setSearchParams,
} = playerBonusesSlice.actions;
