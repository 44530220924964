import { type PresetColor, type ThemeMode } from '@features/appConfig/types';
import DefaultTheme from '@themes/theme/default';
import OrangeTheme from '@themes/theme/Orange';
import PlatformTheme from '@themes/theme/Platform';
import { type PalettesProps } from '@themes/theme/presetPalettes';
import { type PaletteThemeProps } from '@themes/types/theme';

const Theme = (
  colors: PalettesProps,
  presetColor: PresetColor,
  mode: ThemeMode
): PaletteThemeProps => {
  switch (presetColor) {
    case 'Orange':
      return OrangeTheme(colors, mode);
    case 'Platform':
      return PlatformTheme(colors, mode);
    default:
      return DefaultTheme(colors);
  }
};

export default Theme;
