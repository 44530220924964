import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ISportsData } from '@modules/common/sportsData/types';

interface InitialState {
  sportsDataFormState: ISportsData;
  sportsDataConfig: {
    isSelectorChanged: boolean;
    isSportsDataError: boolean;
  };
}

const initialState: InitialState = {
  sportsDataConfig: {
    isSelectorChanged: false,
    isSportsDataError: false,
  },
  sportsDataFormState: {
    includedSportsConditions: {
      combinator: 'OR',
      sportsDataConditionFields: [],
    },
    excludedSportsConditions: {
      combinator: 'AND',
      sportsDataConditionFields: [],
    },
  },
};

const sportsDataSlice = createSlice({
  initialState,
  name: 'sportsDateSlice',
  reducers: {
    resetIncludedSportsData(state: InitialState) {
      state.sportsDataFormState.includedSportsConditions =
        initialState.sportsDataFormState.includedSportsConditions;
    },
    resetExcludedSportsData(state: InitialState) {
      state.sportsDataFormState.excludedSportsConditions =
        initialState.sportsDataFormState.excludedSportsConditions;
    },
    setSportsDataConfig(
      state: InitialState,
      action: PayloadAction<Partial<InitialState['sportsDataConfig']>>
    ) {
      state.sportsDataConfig = { ...state.sportsDataConfig, ...action.payload };
    },
    setExcludedSportsData(
      state: InitialState,
      action: PayloadAction<Partial<ISportsData['excludedSportsConditions']>>
    ) {
      state.sportsDataFormState.excludedSportsConditions = {
        ...state.sportsDataFormState.excludedSportsConditions,
        ...action.payload,
      };
    },
    setIncludedSportsData(
      state: InitialState,
      action: PayloadAction<Partial<ISportsData['includedSportsConditions']>>
    ) {
      state.sportsDataFormState.includedSportsConditions = {
        ...state.sportsDataFormState.includedSportsConditions,
        ...action.payload,
      };
    },
  },
});

export default sportsDataSlice.reducer;

export const {
  setSportsDataConfig,
  setExcludedSportsData,
  setIncludedSportsData,
  resetIncludedSportsData,
  resetExcludedSportsData,
} = sportsDataSlice.actions;
