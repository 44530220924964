import { type FC } from 'react';
import { Suspense } from 'react';

import { Loader } from '@components';

export const Suspendable =
  <T extends Record<string, unknown>>(Component: FC<T>) =>
  (props: any) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
