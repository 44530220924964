import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const CampaignActivityReport = Suspendable(
  lazy(() =>
    import('@modules/campaignActivityReport/controller').then((module) => ({
      default: module.CampaignActivityReportController,
    }))
  )
);

const Bonuses = Suspendable(
  lazy(() =>
    import('@modules/playerBonuses/controller/index').then((module) => ({
      default: module.PlayerBonusesController,
    }))
  )
);

const Offers = Suspendable(
  lazy(() =>
    import('@modules/playerOffers/controller/index').then((module) => ({
      default: module.PlayerOffers,
    }))
  )
);

const OfferDetails = Suspendable(
  lazy(() =>
    import(
      '@modules/playerOffers/submodules/PlayerOfferDetails/controller'
    ).then((module) => ({
      default: module.PlayerOfferDetailsController,
    }))
  )
);

export const CampaignActivityRoutes: RouteObject[] = [
  {
    element: <CampaignActivityReport />,
    path: ROUTES.CAMPAIGN_ACTIVITY_REPORT.ROOT,
  },
  {
    element: <Bonuses />,
    path: ROUTES.CAMPAIGN_ACTIVITY_REPORT.BONUSES,
  },
  {
    element: <Offers />,
    path: ROUTES.CAMPAIGN_ACTIVITY_REPORT.OFFERS,
  },
  {
    element: <OfferDetails />,
    path: ROUTES.CAMPAIGN_ACTIVITY_REPORT.OFFER_DETAILS,
  },
];
