import { OPERATOR_TYPE } from '@features/filters/types';

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  code: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  bonusName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  enabled: { value: false, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
};
