export enum ROLE {
  GLOBAL = 'GLOBAL',
  IN_ORGANIZATION = 'IN_ORGANIZATION',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum PERMISSION {
  ADD_PLAYER_CODE = 'add:player_code',
  CANCEL_PLAYER_CODE = 'cancel:player_code',
  CANCEL_PLAYER_CODE_REPORT = 'cancel:bonus_system_player_code',
  CREATE_MEMBER_BONUSES = 'create:member_bonuses',
  EDIT_APP_SETTINGS = 'edit:ext_bonus_system_application_setting',
  EDIT_BONUSES = 'edit:ext_bonus_system_bonuses',
  EDIT_CAMPAIGNS = 'edit:ext_bonus_system_automatic_campaigns',
  EDIT_CODE_CAMPAIGNS = 'edit:bonus_system_code_campaigns',
  EDIT_SCHEDULED_CAMPAIGNS = 'edit:ext_bonus_system_scheduled_campaigns',
  EXPORT_BONUSES = 'export:ext_bonus_system_bonuses',
  EXPORT_CAMPAIGNS = 'export:ext_bonus_system_automatic_campaigns',
  EXPORT_CODE_CAMPAIGNS = 'export:bonus_system_code_campaigns',
  EXPORT_PLAYER_CODE = 'export:player_codes',
  EXPORT_PLAYER_CODE_REPORT = 'export:bonus_system_player_bonuses_report',
  EXPORT_PLAYER_REPORT = 'export:ext_bonus_system_player_bonuses_report',
  EXPORT_SCHEDULED_CAMPAIGNS = 'export:ext_bonus_system_scheduled_campaigns',
  PLAYER_OFFERS_CRM_CANCEL = 'cancel:crm_player_offers',
  PLAYER_OFFERS_CRM_EXPORT = 'export:crm_player_offers',
  PLAYER_OFFERS_CRM_READ = 'read:crm_player_offers',
  PLAYER_OFFERS_EXTERNAL_CANCEL = 'cancel:ext_bonus_players_offers',
  PLAYER_OFFERS_EXTERNAL_EXPORT = 'export:ext_bonus_players_offers',
  PLAYER_OFFERS_EXTERNAL_READ = 'read:ext_bonus_players_offers',
  READ_ACTIVITY_REPORT = 'read:ext_bonus_system_campaign_activity_report',
  READ_ACTIVITY_REPORT_EXPORT = 'export:ext_bonus_system_campaign_activity_report',
  READ_APP_SETTINGS = 'read:ext_bonus_system_application_setting',
  READ_BONUSES = 'read:ext_bonus_system_bonuses',
  READ_CAMPAIGNS = 'read:ext_bonus_system_automatic_campaigns',
  READ_CODE_CAMPAIGNS = 'read:bonus_system_code_campaigns',
  READ_PLAYER_CODE = 'read:player_codes',
  READ_PLAYER_CODE_REPORT = 'read:bonus_system_player_code_report',
  READ_PLAYER_REPORT = 'read:ext_bonus_system_player_bonuses_report',
  READ_SCHEDULED_CAMPAIGNS = 'read:ext_bonus_system_scheduled_campaigns',
}

export type PermissionModules =
  | 'player_code'
  | 'player_codes'
  | 'member_bonuses'
  | 'crm_player_offers'
  | 'ext_bonus_players_offers'
  | 'ext_bonus_system_bonuses'
  | 'bonus_system_player_code'
  | 'bonus_system_code_campaigns'
  | 'bonus_system_player_code_report'
  | 'bonus_system_player_bonuses_report'
  | 'ext_bonus_system_application_setting'
  | 'ext_bonus_system_automatic_campaigns'
  | 'ext_bonus_system_scheduled_campaigns'
  | 'ext_bonus_system_player_bonuses_report'
  | 'ext_bonus_system_campaign_activity_report';
