import { DATE_TIME_FULL_FORMAT } from '@constants/date';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formattedDate = (
  date: Date | null | number,
  timeZone: string = 'GMT'
): string => {
  if (!date || !dayjs(date).isValid()) {
    return '';
  }

  return dayjs(date).tz(timeZone).format(DATE_TIME_FULL_FORMAT);
};
