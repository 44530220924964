import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const PlayerCodesReport = Suspendable(
  lazy(() =>
    import('@modules/playerCodes/controller').then((module) => ({
      default: module.PlayerCodesReportController,
    }))
  )
);

export const PlayerCodesRoutes: RouteObject[] = [
  {
    element: <PlayerCodesReport />,
    path: ROUTES.CAMPAIGN_CODES.ROOT,
  },
];
