import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
  type IInitialFilter,
  type ISearchParams,
  OPERATOR_SORT,
  OPERATOR_TYPE,
} from '@features/filters/types';
import { getCurrentDateStartEndTime } from '@utils/getCurrentDateStartEndTime';

interface InitialState {
  searchParams: ISearchParams;
  expandedRows: Record<string, boolean>;
  prevFilters: Record<string, IInitialFilter>;
}

export const initialCampaignActivitySearchParams = {
  page: 1,
  size: 10,
  sorts: [
    {
      field: 'campaign_name',
      order: OPERATOR_SORT.DESC,
    },
  ],
  filters: [
    {
      value: true,
      inversion: false,
      field: 'enabled',
      type: OPERATOR_TYPE.EQUAL,
    },
    {
      inversion: false,
      field: 'given_at',
      type: OPERATOR_TYPE.GREATER_THAN_OR_EQUAL,
      value: getCurrentDateStartEndTime('start').valueOf(),
    },
    {
      inversion: false,
      field: 'given_at',
      type: OPERATOR_TYPE.LESS_THAN_OR_EQUAL,
      value: getCurrentDateStartEndTime('end').valueOf(),
    },
  ],
};

const initialState: InitialState = {
  prevFilters: {},
  expandedRows: {},
  searchParams: initialCampaignActivitySearchParams,
};

const campaignActivitySlice = createSlice({
  initialState,
  name: 'campaignActivitySlice',
  reducers: {
    resetExpandedRows(state: InitialState) {
      state.expandedRows = initialState.expandedRows;
    },
    setSearchParams(state: InitialState, action: PayloadAction<ISearchParams>) {
      state.searchParams = action.payload;
    },
    setPrevFilters(
      state: InitialState,
      action: PayloadAction<Record<string, IInitialFilter>>
    ) {
      state.prevFilters = action.payload;
    },
    addExpandedRow(
      state: InitialState,
      action: PayloadAction<Record<string, boolean>>
    ) {
      state.expandedRows = { ...state.expandedRows, ...action.payload };
    },
    updateExpandedRow(
      state: InitialState,
      action: PayloadAction<Record<string, boolean>>
    ) {
      state.expandedRows = {
        ...state.expandedRows,
        [Object.keys(action.payload)[0]]: Object.values(action.payload)[0],
      };
    },
  },
});

export default campaignActivitySlice.reducer;

export const {
  addExpandedRow,
  setPrevFilters,
  setSearchParams,
  updateExpandedRow,
  resetExpandedRows,
} = campaignActivitySlice.actions;
