import { StrictMode, useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

import { ENV } from '@constants';
import { handleRejectSentryErrors } from '@utils/handleRejectSentryErrors';

import App from './App';

import './index.css';

// Description: Lazy load error handling
window.addEventListener('vite:preloadError', (event) => {
  event.preventDefault();
  event.stopPropagation();
  window.location.reload();
});

Sentry.init({
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  dsn: ENV.VITE_SENTRY_DSN,
  environment: ENV.VITE_ENVIRONMENT,
  enabled: ENV.MODE === 'production', // comment it for debugging localhost
  beforeSend: handleRejectSentryErrors,
  tracePropagationTargets: [
    // add all trace api routes
    'localhost',
    ENV.VITE_BONUS_API_URL,
    ENV.VITE_BONUS_SERVICE_API_URL,
    ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL,
  ],
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      matchRoutes,
      useNavigationType,
      createRoutesFromChildren,
    }),
  ],
  ignoreErrors: [
    'React ErrorBoundary Error: Element type is invalid. Received a promise that resolves to: undefined. Lazy element type must resolve to a class or function.',
    'Error: Element type is invalid. Received a promise that resolves to: undefined.',
    'Element type is invalid. Received a promise that resolves to: undefined. Lazy element type must resolve to a class or function.',
    /Lazy element/i,
  ],
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);
