import { lazy } from 'react';
import { type RouteObject } from 'react-router-dom';

import { Suspendable } from '@components';

import { ROUTES } from '@constants';

const IframeModeController = Suspendable(
  lazy(() =>
    import('@modules/iframeMode/controller').then((module) => ({
      default: module.IframeMode,
    }))
  )
);
export const IframeMode: RouteObject[] = [
  {
    path: ROUTES.IFRAME_MODE.ROOT,
    element: <IframeModeController />,
  },
];
