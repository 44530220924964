import { createApi } from '@reduxjs/toolkit/query/react';

import { API, ENV } from '@constants';
import { type ISearchParams } from '@features/filters/types';
import { type ICampaignActivitySearch } from '@modules/campaignActivityReport/types';
import { baseQueryCreator } from '@utils/baseQueryCreator';

export const campaignActivityReportingServiceSlice = createApi({
  endpoints: () => ({}),
  refetchOnReconnect: true,
  reducerPath: 'сampaignActivityReportingService',
  baseQuery: (...args) =>
    baseQueryCreator(ENV.VITE_PLAYER_REPORTING_SERVICE_API_URL)(...args),
});
export const campaignActivityApi = campaignActivityReportingServiceSlice
  .enhanceEndpoints({
    addTagTypes: ['campaign-activity-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCampaignActivityList: builder.query<
        ICampaignActivitySearch,
        ISearchParams
      >({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.CAMPAIGN_MANAGEMENT.ACTIVITY_REPORT,
        }),
      }),
    }),
  });

export const { useLazyGetCampaignActivityListQuery } = campaignActivityApi;
