import { type TagDescription } from '@reduxjs/toolkit/query';

import { apiSlice } from '@api/apiSlice';
import { API } from '@constants';
import { type ISearchParams } from '@features/filters/types';
import { type IBonusSearch } from '@modules/bonusManagement/types';

export const bonusesApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['bonus-list', 'bonus'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBonuses: builder.query<IBonusSearch, ISearchParams>({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.BONUS_MANAGEMENT.GRID,
        }),
        providesTags: (result) =>
          result
            ? [
                { id: 'LIST', type: 'bonus-list' },
                ...(result?.data?.map(({ id }) => ({
                  id,
                  type: 'bonus-list',
                })) as Array<TagDescription<'bonus-list'>>),
              ]
            : [{ type: 'bonus-list' }],
      }),
      exportData: builder.mutation<
        { response: string; headers: Record<string, string> },
        ISearchParams
      >({
        query: (payload) => ({
          body: payload,
          method: 'POST',
          url: API.BONUS_MANAGEMENT.EXPORT,
          responseHandler: async (response: Response) => {
            const text = await response.text();
            const headers: Record<string, string> = {};
            response.headers.forEach((value: string, key: string) => {
              headers[key] = value;
            });
            return { headers, response: text };
          },
        }),
      }),
    }),
  });

export const { useExportDataMutation, useLazyGetBonusesQuery } = bonusesApi;
